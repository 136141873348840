import { useEffect, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserIP, setCreatePaypalOrder, setCreatePaypalSubscription, setUserIP } from '../../store/user';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import services from '../../services';
import React from 'react';
import { PayPalButtons } from '@paypal/react-paypal-js';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASKET_CACHE_KEY } from '../../utils/handleLocalStorage';
// import { Base64 } from 'js-base64';
import HandleShowRecurring from '../../utils/showRecurringOnDataLayer';
import { sha256 } from 'js-sha256';
import { v4 as uuidv4 } from 'uuid';
import HandleShowAdminCost from '../../utils/handleAdminCost';
import ConfirmModal from './components/confirmModal';
import IsGTMScriptLoaded from '../../utils/isGTMScriptLoaded';
import { generateLinkWithLang } from '../../utils/linkGenerator';
import { useTranslation } from 'react-i18next';
import {Dropin, Card, ApplePay, GooglePay, Bancontact, EPS, } from '@adyen/adyen-web';
import { AdyenCheckout } from '@adyen/adyen-web';
import '@adyen/adyen-web/styles/adyen.css';
import { getCookie } from '../../utils/cookie';

const StepThreeWithLocalStorage = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const { isLaylatulQadr } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userIP = useSelector(selectUserIP);
  const uuid = uuidv4();
  // const paypalId = useSelector(selectPaypalPlanId);
  const [paypalId, setPaypalId] = useState();
  const [selectedMethod, setSelectedMethod] = useState('Card');
  const [subscriptionError, setSubscriptionError] = useState({ state: false, message: '' });
  const [isLoading, setIsloading] = useState(false);
  const [hasMultipleCurrencyError, setHasMultipleCurrencyError] = useState(false);
  const [isSubscription, setIsSubscription] = useState(false);
  const [isCryptoEnable, setIsCryptoEnable] = useState(true);
  const [isAdyenEnable, setIsAdyenEnable] = useState(false);
  const [adyenCountryCode, setAdyenCountryCode] = useState('AU');
  // const [isPaypalEnable, setIsPaypalEnable] = useState(false);
  const [userProductsForStripePassing, setUserProductsForStripePassing] = useState();
  const [userProductsForPaypalPassing, setUserProductsForPaypalPassing] = useState();
  const [basketStates, setBasketStates] = useState({});
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [message] = useState(
    'PayPal does not support admin contributions and multiple subscriptions, please use a credit or debit card or select a donation without increasing the quantity if you would like use PayPal.',
  );
  const [messageCrypto, setMessageCrypto] = useState(
    `Sorry crypto doesn't support subscriptions, please use a credit or debit card or select single/one-time donation if you would like to use Crypto.`,
  );
  const paymentMethodsOnlyLayla = [
    {
      title: 'Card',
      imgName: '4logos',
    },
  ];

  const paymentMethods = [
    {
      title: 'Card',
      imgName: '4logos',
    },
    {
      title: 'Google Pay',
      imgName: 'payment-card-gpay-apple',
    },
    // {
    //     title: 'Google Pay',
    //     imgName: 'google-pay'
    // },
    // {
    //     title: 'Apple Pay',
    //     imgName: 'apple-pay'
    // },
    {
      title: 'Paypal',
      imgName: 'paypal',
    },
    {
      title: 'Crypto',
      imgName: 'crypto',
    },
    {
      title: 'Adyen',
      imgName: 'adyen',
    },
  ];
  const stripe = useStripe();
  const notify = text =>
    toast.error(text, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const [showModal, setShowModal] = useState(false);
  const campaignData = getCookie('campaign_data');
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const getStripeSession = async () => {
    setIsloading(true);
    let tempUserIP = userIP;
    if (userIP === '') {
      fetch('https://pro.ip-api.com/json?key=04S31DLsvcX7pze')
        .then(res => res.json())
        .then(response => {
          dispatch(setUserIP(response.query));
          tempUserIP = response.query;
        })
        .catch((data, status) => {
          console.log('Request failed:', data);
        });
    }
    let tempProduct = {
      stripe_customer_id: basketStates.stripe_customer_id,
      currency: basketStates.currency,
      mode: basketStates.mode,
      products: userProductsForStripePassing,
      referal: document.referrer,
      plaque_name: userProductsForStripePassing.find(item => item.plaque_name !== 'For the sake of Allah SWT')?.plaque_name ?? '',
      payment_flow: basketStates.paymentFlow,
      host: window.location.protocol + '//' + window.location.host,
      ip: tempUserIP,
      ...(campaignData || {}),
    };
    console.log('get-SessionId=', tempProduct);
    handlePaymentMethodDataLayer('Stripe', selectedMethod);
    try {
      let response = '';
      if (isLaylatulQadr) {
        tempProduct.start_date = basketStates.products[0].start_date;
        tempProduct.end_date = basketStates.products[0].end_date;
        tempProduct.continue_donation = basketStates.products[0].continue_donation ? 1 : 0;
        tempProduct.total_amount = basketStates.grandTotal;
        delete tempProduct.mode;
        response = await services.getSessionIdFuturePayment(tempProduct);
        console.log('response=', response);
      } else {
        response = await services.getSessionId(tempProduct);
      }
      // const response = await services.getSessionId(tempProduct);
      console.log('response getSessionId', response.data, response.data.checkout_session.id);
      handleStripe(response.data.checkout_session.id);
    } catch (error) {
      setIsloading(false);
      const err = error?.response?.data?.errors;
      console.log(err, error);
      if (error?.response?.status === 409) {
        setShowConfirmModal(true);
      }
      if (Object.keys(err).length > 0) {
        for (const key in err) {
          notify(err[key][0]);
        }
      } else {
        notify('Something went wrong! please try later');
      }
      console.error('error========', error);
    }
  };
  const getAdyenSession = async () => {
    setIsloading(true);
    let tempUserIP = userIP;
    if (userIP === '') {
    fetch('https://pro.ip-api.com/json?key=04S31DLsvcX7pze')
        .then(res => res.json())
        .then(response => {
          dispatch(setUserIP(response.query));
          tempUserIP = response.query;
        })
        .catch((data, status) => {
          console.log('Request failed:', data);
        });
    }
    let tempProduct = {
      stripe_customer_id: basketStates.stripe_customer_id,
      currency: basketStates.currency,
      mode: basketStates.mode,
      products: userProductsForStripePassing,
      referal: document.referrer,
      plaque_name: userProductsForStripePassing.find(item => item.plaque_name !== 'For the sake of Allah SWT')?.plaque_name ?? '',
      payment_flow: basketStates.paymentFlow === "OTHER" ? "UK" : basketStates.paymentFlow,
      host: window.location.protocol + '//' + window.location.host,
      ip: tempUserIP,
      gateway: 'adyen',
      ...(campaignData || {}),
    };
    handlePaymentMethodDataLayer('Adyen', selectedMethod);
    try {
      let response = await services.getSessionId(tempProduct);
      console.log('adyen response=', response.data.checkout_session);
      initializeAdyenCheckout(response.data.checkout_session);
      setIsloading(false);
    } catch (error) {
      setIsloading(false);
      const err = error?.response?.data?.errors;
      console.log(err, error);
      if (error?.response?.status === 409) {
        setShowConfirmModal(true);
      }
      if (Object.keys(err).length > 0) {
        for (const key in err) {
          notify(err[key][0]);
        }
      } else {
        notify('Something went wrong! please try later');
      }
      console.error('error========', error);
    }
  };
  const handleStripeAdyen = () => {
    if (basketStates.mode === 'payment') {
      if (selectedMethod === 'Adyen') {
        getAdyenSession();
      } else if ((selectedMethod === 'Card' || selectedMethod === 'Google Pay' || selectedMethod === 'Apple Pay') && (basketStates.paymentFlow === 'AUD' || basketStates.paymentFlow === 'UK' || basketStates.paymentFlow === 'OTHER')) {
        if (basketStates.paymentFlow === 'UK' || basketStates.paymentFlow === 'OTHER') {
          getAdyenSession(); // 100% chance to run Adyen
        } else {
          getAdyenSession(); // 100% chance to run Adyen
        }
      } else {
        getStripeSession();
      }
    } else {
      getStripeSession();
    }
  };
  const handleStripe = id => {
    // setIsloading(true);
    if (id) {
      // HandleLocalStorage(basketStates,true);
      stripe.redirectToCheckout({
        sessionId: id,
      });
      setIsloading(false);
    } else {
      setIsloading(false);
      console.log('error');
    }
  };
  const handleOptionChange = value => {
    let temp = value;
    if (isCryptoEnable && value === 'Crypto') {
      console.log('item select=', value, isCryptoEnable);
      setSelectedMethod(temp);
    }
    // if (!(isSubscription && value === 'Paypal' | !(!isCryptoEnable && value === 'Crypto'))) {
    if (isSubscription && value === 'Paypal') {
      notify(message);
    } else {
      if (value === 'Crypto' && !isCryptoEnable) {
        notify(messageCrypto);
      } else {
        setSelectedMethod(temp);
      }
    }
  };
  const handleApprovePaypalSubscription = async data => {
    let tempPaypalAccount = basketStates.paymentFlow
      ? basketStates.paymentFlow === 'USD'
        ? 'US'
        : basketStates.paymentFlow === 'AUD'
        ? 'AU'
        : 'UK'
      : 'UK';
    let tempData = {
      stripe_customer_id: basketStates.stripe_customer_id,
      paypal_plan_id: paypalId, // need to check ****************************************************************
      facilitator_access_token: data.facilitatorAccessToken,
      subscription_id: data.subscriptionID,
      order_id: data.orderID,
      payment_source: data.paymentSource,
      product: userProductsForPaypalPassing[0].id, // need to check ****************************************************************
      currency: basketStates.currency,
      referal: document.referrer,
      total_amount: basketStates.grandTotal,
      plaque_name: userProductsForPaypalPassing.find(item => item.plaque_name !== 'For the sake of Allah SWT')?.plaque_name ?? '',
      host: window.location.protocol + '//' + window.location.host,
      ip: userIP,
      products: userProductsForPaypalPassing,
      paypal_account: tempPaypalAccount,
      ...(campaignData || {}),
    };
    // console.error('tempData======== before send', tempData);
    try {
      const response = await services.createPaypalSubscription(tempData);
      // const response = await services.createPaypalSubscriptionStaging(tempData);
      // HandleLocalStorage(basketStates,true);
      dispatch(setCreatePaypalSubscription(response.data));
      navigate(generateLinkWithLang(`/success?gateway=paypal&mode=subscription&currency=${basketStates.currency}&id=${data.orderID}`, lang));
      console.log('response createPaypalOrder', response);
    } catch (error) {
      // toast.error('Your order has paid successfully, Something went wrong at this moment, we will send you your receipt soon!');
      console.error('error======== before notify', error);
      // notify('Your order has paid successfully, Something went wrong at this moment, we will send you your receipt soon!');
      console.error('error========', error);
    }
  };
  const handleApprovePaypalOrder = async details => {
    let tempPaypalAccount = basketStates.paymentFlow
      ? basketStates.paymentFlow === 'USD'
        ? 'US'
        : basketStates.paymentFlow === 'AUD'
        ? 'AU'
        : 'UK'
      : 'UK';
    // let tempPaypalAccount = basketStates.paymentFlow ? basketStates.paymentFlow === 'OTHER' ? 'UK' : basketStates.paymentFlow : 'UK'
    let tempUserIP = userIP;
    if (userIP === '') {
      fetch('https://pro.ip-api.com/json?key=04S31DLsvcX7pze')
        .then(res => res.json())
        .then(response => {
          dispatch(setUserIP(response.query));
          tempUserIP = response.query;
        })
        .catch((data, status) => {
          console.log('Request failed:', data);
        });
    }
    let tempData = {
      // payer_id: details.payer.payer_id,
      name: `${details.payer.name.given_name} ${details.payer.name.surname}`,
      email_paypal: details.payer.email_address,
      email: basketStates.user.email,
      // paypal_order_id: details.id,
      // paypal_order_id: details.purchase_units[0].payments.captures[0].id,
      paypal_order_id: details.id,
      paypal_transaction_id: details.purchase_units[0].payments.captures[0].id,
      paypal_email_address: details.payer.email_address,
      paypal_user_id: details.payer.payer_id,
      total_amount: basketStates.grandTotal,
      currency: basketStates.currency,
      products: userProductsForPaypalPassing,
      referal: document.referrer,
      host: window.location.protocol + '//' + window.location.host,
      ip: tempUserIP,
      plaque_name: userProductsForPaypalPassing.find(item => item.plaque_name !== 'For the sake of Allah SWT')?.plaque_name ?? '',
      paypal_account: tempPaypalAccount,
      ...(campaignData || {}),
    };
    // console.log("tempData handleApprovePaypalOrder", tempData)
    try {
      const response = await services.createPaypalOrder(tempData);
      // HandleLocalStorage(basketStates,true);
      dispatch(setCreatePaypalOrder(response.data));
      navigate(generateLinkWithLang(`/success?gateway=paypal&mode=order&currency=${basketStates.currency}&id=${details.id}`, lang));
      console.log('response createPaypalOrder', response);
    } catch (error) {
      // notify('Your order has paid successfully, Something went wrong at this moment, we will send you your receipt soon!');
      console.error('error========', error);
    }
  };
  const checkIsIOS = () => {
    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      setSelectedMethod('Apple Pay');
    } else {
      setSelectedMethod('Card');
    }
  };
  const handlePaymentMethodDataLayer = (method, paymentType) => {
    // console.log("datalayer payment method", userProducts, createdUser, method, paymentType);
    let tempItems = basketStates.products.map(item => {
      return {
        item_id: item.product.creator,
        item_name: item.product.name,
        item_brand: item.product.category || '',
        item_category: item.product.category || '',
        item_description: item.product.description || '',
        price: item.amount,
        quantity: item.quantity,
      };
    });
    let dataLayerTemp = {
      event: 'add_payment_method',
      name: basketStates.user.name,
      email: basketStates.user.email,
      phone: basketStates.user.phone,
      user_email_hashed: sha256(basketStates.user.email),
      donor_id: basketStates.user.user_id,
      pay_id: basketStates.stripe_customer_id,
      frequency: HandleShowRecurring(basketStates.recurring),
      ecommerce: {
        value: basketStates.grandTotal,
        tax: basketStates.adminCost,
        currency: basketStates.currency.toUpperCase(),
        shipping_tier: method,
        payment_type: paymentType,
        items: tempItems,
      },
    };
    window.dataLayer = window.dataLayer || [];
    IsGTMScriptLoaded() && window.dataLayer.push(dataLayerTemp);
  };
  const handleCreateCryptoOrder = async () => {
    let tempData = {
      name: basketStates.user.name,
      email: basketStates.user.email,
      id: basketStates.user.id,
      total_amount: basketStates.grandTotal,
      currency: basketStates.currency,
      products: userProductsForPaypalPassing,
      referal: document.referrer,
      host: window.location.protocol + '//' + window.location.host,
      ip: userIP,
      uuid: uuid || uuidv4(),
      plaque_name: userProductsForPaypalPassing.find(item => item.plaque_name !== 'For the sake of Allah SWT')?.plaque_name ?? '',
    };
    handlePaymentMethodDataLayer('Crypto', selectedMethod);
    try {
      const response = await services.createCryptoOrder(tempData);
      console.log('response createCryptoOrder', response);
    } catch (error) {
      console.error('error========', error);
    }
  };
  const handleSaveCryptoWalletAddress = async wallet_address => {
    let tempData = {
      id: uuid || uuidv4(),
      address: wallet_address,
    };
    try {
      const response = await services.saveWalletAddress(tempData);
      console.log('response save crypto address', response);
    } catch (error) {
      console.error('error========', error);
    }
  };
  const handleCryptoCurrency = (event, elements) => {
    // let coin_name = ''
    // if (elements.querySelector(".section .mustsend strong")) {
    //     coin_name = elements.querySelector(".section .mustsend strong").textContent.split(' ');
    //     coin_name = coin_name[coin_name.length - 1];
    //     console.log("elements address=", coin_name[coin_name.length - 1])
    // }
    // elements.querySelector(".address") && console.log("elements address=",elements.querySelector(".address").textContent)
    if (event.target.className === 'btnpay') {
      handleSaveCryptoWalletAddress(elements.querySelector('.address').textContent);
      navigate(generateLinkWithLang('/processing-crypto', lang));
    }
    if (event.target.className === 'btncancel') {
      handleSaveCryptoWalletAddress(elements.querySelector('.address').textContent);
      navigate(generateLinkWithLang('/cancel-crypto', lang));
    }
    // console.log("elements clicked=",event, event.target,event.target.className)
  };
  // const handleClick = () => {
  //     handleCreateCryptoOrder();
  //     const elements = document.querySelector(".pay-modal");
  //     elements.addEventListener('click', (event) => handleCryptoCurrency(event, elements));
  //     console.log("elements=", elements)
  // }
  const handleClick = () => {
    handleCreateCryptoOrder();
    const elements = document.querySelector('.pay-modal');
    const elementsInner = document.querySelector('.pay-modal_inner');
    myObserver.observe(elementsInner);
    elements.addEventListener('click', event => handleCryptoCurrency(event, elements));
    console.log('elements=', elements);
  };
  const myObserver = new ResizeObserver((entries, observer) => {
    const elementsInner = document.querySelector('.pay-modal_inner');
    for (let entry of entries) {
      console.log('size changed=', entry, entry.contentRect.height, observer);
      if (entry.contentRect.height > 370) {
        handleSaveCryptoWalletAddress(document.querySelector('.pay-modal .address').textContent);
        myObserver.unobserve(elementsInner);
      }
    }
  });
  const LOG_ENDPOINT = 'https://matw.finbix.io/254fd2a6-5977-40be-8fad-95b5edfa0c98/api/checkout/logs';

// Helper function to send error logs to the server
  const sendErrorToServer = async (errorText) => {
    // Retrieve the host from the current window location
    const host = window.location.host;

    // Append the host to the original error text
    const errorWithHost = `${errorText} Host: ${host}`;

    try {
      await fetch(LOG_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        // Send the error along with the host information
        body: JSON.stringify([{ error: errorWithHost }]),
      });
      if ((!errorWithHost.startsWith('Payment failed with resultCode:')) &&(!errorWithHost.startsWith('Payment error: ApplePay UI dismissed Host'))) {
        getStripeSession();
      }
    } catch (loggingError) {
      console.error('Failed to send error log to server:', loggingError);
    }
  };

// Helper function to wait for an element with a timeout
  const waitForElement = (selector, timeout = 5000) => {
    return new Promise((resolve, reject) => {
      const intervalTime = 100; // Check every 100ms
      let elapsedTime = 0;

      const interval = setInterval(() => {
        const element = document.querySelector(selector);
        if (element) {
          clearInterval(interval);
          resolve(element);
        } else {
          elapsedTime += intervalTime;
          if (elapsedTime >= timeout) {
            clearInterval(interval);
            reject(new Error(`Element ${selector} not found within ${timeout}ms`));
          }
        }
      }, intervalTime);
    });
  };

// Updated initializeDropin function with timeout
  const initializeDropin = async (checkout) => {
    try {
      // Check if 'dropin-container' exists
      let dropinContainer = document.getElementById('dropin-container');

      if (!dropinContainer) {
        // If it doesn't exist, ensure the modal is shown
        setShowModal(true);

        // Wait for the 'dropin-container' to appear with a timeout
        dropinContainer = await waitForElement('#dropin-container', 5000); // 5 seconds timeout
      }

      // Initialize and mount Dropin
      const dropin = new Dropin(checkout, {
        paymentMethodComponents: [Card, ApplePay, GooglePay, Bancontact, EPS],
        paymentMethodsConfiguration: {
          card: {
            hasHolderName: true, // Enable the Cardholder Name field
            holderNameRequired: true, // Make the Cardholder Name field required
          },
        },
        instantPaymentTypes: ['card', 'applepay', 'googlepay', 'Bancontact', 'EPS', 'bancontact', 'eps', 'ideal', 'iDEAL'],
      }).mount(dropinContainer);

      // Optionally, handle Dropin events here
    } catch (error) {
      console.error('Error mounting Adyen Dropin:', error);
      await sendErrorToServer(`Error mounting Adyen Dropin: ${error.message || error}`);
      //notify('Failed to initialize payment interface. Please try again.');

      // Optionally, hide the modal if Dropin initialization fails
      setShowModal(false);
    }
  };

  const initializeAdyenCheckout = async (obj) => {
    let session;
    let identity;
    let checkout_identity;

    try {
      session = obj.gateway_data.session_data;
      identity = obj.payment_intent;
      checkout_identity = obj.checkout_identity;
      console.log('adyen=', obj);
    } catch (sessionError) {
      console.error('Session creation error:', sessionError);
      await sendErrorToServer(`Session creation error: ${sessionError.message || sessionError}`);
      throw sessionError;
    }

    try {
      setShowModal(true);
      console.log('adyenCountryCode', adyenCountryCode);
      const checkout = await AdyenCheckout({
        clientKey: 'live_4FZWD44MJRFZDDWPNJBQKFJP6AIY6GRZ', // Replace with your actual Adyen client key
        session: {
          id: identity, // Unique identifier for the payment session.
          sessionData: session, // The payment session data.
        },
        environment: 'live', // Use "live" for production
        countryCode: adyenCountryCode || 'AE', // Replace "AE" with the actual country code if available
        locale: "en-US",
        onPaymentCompleted: (result) => {
          console.log('Payment successful:', result);
          if (result.resultCode === 'Authorised') {
            handleSendAdyenResultOnSuccess(result.sessionResult, checkout_identity, identity);
          }
          setShowModal(false);
          // Handle success here (e.g., navigate to success page)
        },
        onPaymentFailed: async (result) => {
          // Log the payment failure
          const errorMessage = `Payment failed with resultCode: ${result.resultCode}`;
          console.error(errorMessage);
          await sendErrorToServer(errorMessage);

          if (result.resultCode === 'Refused') {
            notify('Your bank declined the donation payment. Please check your card details and try again.');
          }
          if (result.resultCode === 'Cancelled') {
            notify('Your bank cancelled the donation payment. Please check your card details and try again.');
          }
          if (result.resultCode === 'Error') {
            notify('There was an error during the donation payment.');
          }
          setShowModal(false);
          // Handle failure here (e.g., display error message to user)
        },

        onError: async (error) => {
          setShowModal(false);
          console.error('Payment error:', error);
          await sendErrorToServer(`Payment error: ${error.message || error}`);
          //notify('Payment error occurred. Please try again.');
        },
      });

      // Initialize Dropin after ensuring the container exists
      await initializeDropin(checkout);
    } catch (error) {
      setShowModal(false);
      console.error('Adyen initialization error:', error);
      await sendErrorToServer(`Adyen initialization error: ${error.message || error}`);
      throw error;
    }
  };


  const handleSendAdyenResultOnSuccess = async (session_result, checkout_identity, identity) => {
    try {
      await services.sendAdyenResult(checkout_identity, session_result);
      navigate(`/success?gateway=adyen&currency=${basketStates.currency}&session_id=${checkout_identity}&transaction_id=${identity}`);
    } catch (error) {
      console.error('Error sending Adyen result:', error);
      notify('We encountered an issue processing your transaction.');
    }
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    let tempStripe = [];
    let tempPaypal = [];
    let tempPaypalId = '';
    // console.log("basketstates",basketStates)
    if (basketStates.products) {
      basketStates.products.map((obj, index) => {
        if (obj.product) {
          tempStripe.push({
            id: obj.product.creator,
            quantity: obj.quantity,
            recurring: obj.recurring,
            plaque_name: obj.onBehalf,
            url: obj.landing_page || '',
          });
          tempPaypal.push({
            id: obj.product.creator,
            quantity: obj.quantity,
            value: obj.amount,
            plaque_name: obj.onBehalf,
            url: obj.landing_page || '',
          });
        }
        return tempStripe;
      });
      console.log('tempstripe=', tempStripe);
      if (basketStates.products[0].product.paypal_plans instanceof Array) {
        tempPaypalId = basketStates.products[0].product.paypal_plans
          .filter(item => {
            return item.recurring === basketStates.recurring.toUpperCase();
          })
          .map(item => {
            let tempPaypalId = item.paypal_plan_id;
            if (basketStates.paymentFlow === 'UK') tempPaypalId = item.uk_paypal_plan_id;
            if (basketStates.paymentFlow === 'USD') tempPaypalId = item.us_paypal_plan_id;
            return tempPaypalId;
          });
      } else {
        tempPaypalId = basketStates.products[0].product.paypal_plans;
      }
      if (basketStates.products[0].product.paypal_subscription_plans && basketStates.products[0].product.paypal_subscription_plans instanceof Array) {
        tempPaypalId = basketStates.products[0].product.paypal_subscription_plans
          .filter(item => {
            // console.log("tempPaypalId in map=", item.recurring.toUpperCase(), basketStates.recurring.toUpperCase(), item.currency.name, basketStates.currency)
            return item.recurring.toUpperCase() === basketStates.recurring.toUpperCase() && item.currency.name === basketStates.currency;
          })
          .map(item => {
            let tempPaypalId = item.paypal_plan_id;
            if (basketStates.paymentFlow === 'UK' && item.uk_paypal_plan_id) tempPaypalId = item.uk_paypal_plan_id;
            if (basketStates.paymentFlow === 'USD' && item.us_paypal_plan_id) tempPaypalId = item.us_paypal_plan_id;
            return tempPaypalId;
          });
      }
      if (basketStates.products[0].product.paypal_plans && basketStates.products[0].product.paypal_plans instanceof Array) {
        tempPaypalId = basketStates.products[0].product.paypal_plans
          .filter(item => {
            return item.recurring.toUpperCase() === basketStates.recurring.toUpperCase();
          })
          .map(item => {
            return item.paypal_plan_id;
          });
      }
      if (tempPaypalId instanceof Array) {
        // console.log("tempPaypalId=", basketStates.products[0].product.paypal_subscription_plans, tempPaypalId)
        setPaypalId(tempPaypalId[0]);
      } else {
        setPaypalId(tempPaypalId);
      }
      console.log('temppaypalid', tempStripe, tempPaypalId, basketStates);
      const _adminCost = Number(HandleShowAdminCost(basketStates.currency));
      if (basketStates.adminCost) {
        tempStripe.push({
          id: 'INT-ADM-OVH-2023',
          // quantity: basketStates.adminCost / _adminCost,
          quantity: basketStates.adminCost,
          recurring: basketStates.recurring,
          plaque_name: 'For the sake of Allah SWT',
        });
        tempPaypal.push({
          id: 'INT-ADM-OVH-2023',
          quantity: basketStates.adminCost / _adminCost,
          // value: basketStates.adminCost,
          value: _adminCost,
          plaque_name: 'For the sake of Allah SWT',
        });
      }
      console.log('temppaypalid', tempStripe, tempPaypalId, basketStates);
      setUserProductsForStripePassing(tempStripe);
      setUserProductsForPaypalPassing(tempPaypal);
      if (basketStates.mode === 'payment') {
        let acceptableCurrenciesForCrypto = () => {
          if (
            basketStates.currency === 'USD' ||
            basketStates.currency === 'CAD' ||
            basketStates.currency === 'EUR' ||
            basketStates.currency === 'GBP'
          ) {
            return true;
          }
          return false;
        };
        if (
          basketStates.currency === 'USD' ||
          basketStates.currency === 'CAD' ||
          basketStates.currency === 'EUR' ||
          basketStates.currency === 'GBP'
        ) {
          setIsCryptoEnable(true);
        } else {
          setMessageCrypto(
            `Sorry crypto doesn't support ${basketStates.currency}, please use a credit or debit card or use (USD, CAD, EUR, GBP) if you would like to use Crypto.`,
          );
          setIsCryptoEnable(false);
          console.log('on currency check', basketStates.currency);
        }
        if (basketStates.currency === 'MYR' || basketStates.currency === 'AED' || basketStates.currency === 'IDR') {
          setIsSubscription(true);
        } else {
          setIsSubscription(false);
        }
        // if (basketStates.paymentFlow === 'AUD' || basketStates.paymentFlow === 'USD') {
        //     setMessageCrypto(`Sorry crypto doesn't support ${basketStates.paymentFlow} Payment Flow.`)
        //     setIsCryptoEnable(false)
        // }
        if (basketStates.paymentFlow === 'UK') {
          // setIsSubscription(true);
          acceptableCurrenciesForCrypto() && setIsCryptoEnable(true);
        }
        if (basketStates.paymentFlow === 'AUD') {
          setIsSubscription(false);
          setIsCryptoEnable(false);
          // setIsPaypalEnable(true);
        }
        if (basketStates.paymentFlow === 'USD') {
          // setIsSubscription(true);
          setIsCryptoEnable(false);
          // setShowPaypal(false);
        }
        if (basketStates.paymentFlow === 'OTHER') {
          setIsSubscription(false);
          acceptableCurrenciesForCrypto() && setIsCryptoEnable(true);
        }
        if (
            (basketStates.paymentFlow === 'AUD' || basketStates.paymentFlow === 'UK' || basketStates.paymentFlow === 'OTHER') &&
          (window.location.href.includes('qa.matwproject.org') ||
              window.location.href.includes('staging.matwcheckout.org') ||
              window.location.href.includes('uat.matwproject.org') ||
            window.location.href.includes('localhost'))
        ) {
          setIsAdyenEnable(true);
        }
      } else {
        setIsCryptoEnable(false);
        setIsAdyenEnable(false);
        setMessageCrypto(
          `Sorry crypto doesn't support subscriptions, please use a credit or debit card or select single/one-time donation if you would like to use Crypto.`,
        );
        // console.log("in if", basketStates.user.currency, basketStates.currency)
        if (basketStates.user.currency && basketStates.user.currency !== basketStates.currency) {
          setSubscriptionError({
            ...subscriptionError,
            state: true,
            message: `Please go back and change your currency to ${basketStates.user.currency} as you are an existing donor, or use a different email address.`,
          });
          setHasMultipleCurrencyError(true);
          setIsSubscription(true);
        } else {
          if (basketStates.products.length > 1) {
            setIsSubscription(true);
          } else {
            if (
              basketStates.currency === 'MYR' ||
              basketStates.currency === 'AED' ||
              basketStates.currency === 'IDR' ||
              basketStates.products[0].quantity > 1 ||
              basketStates.adminCost !== 0
            ) {
              setIsSubscription(true);
            } else {
              setIsSubscription(false);
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basketStates]);
  useEffect(() => {
    if (userIP === '') {
      fetch('https://pro.ip-api.com/json?key=04S31DLsvcX7pze')
        .then(res => res.json())
        .then(response => {
          dispatch(setUserIP(response.query));
          //  console.log("Country is : ", response);
          setAdyenCountryCode(response.countryCode);
          console.log('response.countryCode', response.countryCode)
          console.log('adyenCountryCode', adyenCountryCode);
        })
        .catch((data, status) => {
          console.log('Request failed:', data);
        });
    } else {
      fetch('https://pro.ip-api.com/json?key=04S31DLsvcX7pze')
          .then(res => res.json())
          .then(response => {
            setAdyenCountryCode(response.countryCode);
            console.log('response.countryCode', response.countryCode)
            console.log('adyenCountryCode', adyenCountryCode);
          })
          .catch((data, status) => {
            console.log('Request failed:', data);
          });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIP]);
  useEffect(() => {
    checkIsIOS();
    setIsloading(false);
    const basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
    if (basketStatesFromLocalStorage) {
      setBasketStates(basketStatesFromLocalStorage);
    }
    if (userIP === '') {
      fetch('https://pro.ip-api.com/json?key=04S31DLsvcX7pze')
        .then(res => res.json())
        .then(response => {
          dispatch(setUserIP(response.query));
          //  console.log("Country is : ", response);
        })
        .catch((data, status) => {
          console.log('Request failed:', data);
        });
    }
    // ReactDOM.findDOMNode(body).getElementsByClassName('snap')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    window.onpopstate = e => {
      setIsloading(false);
    };
    if (window && document) {
      const script = document.createElement('script');
      const body = document.getElementsByTagName('body')[0];
      script.src = 'https://apps.newagecrypto.com/widget/nacepay.js?v4';
      body.appendChild(script);
      // script.addEventListener('load', () => {
      //     window.hbspt.forms.create({
      //         // this example embeds a Hubspot form into a React app but you can tweak it for your use case
      //         // any code inside this 'load' listener will run after the script is appended to the page and loaded in the client
      //     })
      // })
    }
  });
  /* -------------------------------------------------------------------------- */
  return (
    <div className="w-full">
      {showConfirmModal && (
        <ConfirmModal
          show={showConfirmModal}
          confirm={() => {
            getStripeSession();
            setShowConfirmModal(false);
          }}
          closeModal={() => setShowConfirmModal(false)}
        />
      )}
      <div className="content p-2 md:p-5">
        {subscriptionError.state && selectedMethod !== 'Paypal' && (
          <div className="p-3 rounded-md border border-[#f60362] bg-red-50 text-[#f60362] flex flex-col md:flex-row justify-center items-center">
            <ExclamationTriangleIcon className="mr-2 w-6 h-6 text-[#f60362]" />
            {subscriptionError.message}
          </div>
        )}
        {/* <div className="flex flex-wrap justify-center gap-4- mt-6"> */}
        <div className="grid grid-cols-4 md:grid-cols-5 gap-4 mt-6">
          {/* <div className="flex- flex-wrap- justify-center grid grid-cols-4 gap-4 mt-6"> */}
          {isLaylatulQadr
            ? paymentMethodsOnlyLayla.map((item, index) => (
                <div
                  key={`payment${index}`}
                  data-tooltip-target
                  // className={`col-span-2 sm:col-span-2 md:col-span-1 border rounded p-0 md:p-3 flex flex-col justify-center items-center md:h-[170px] transition-all duration-150 ease-linear ${selectedMethod === item.title ? 'border-[#00a3da] bg-blue-100' : 'border-stone-200'} ${isSubscription && index === 3 ? 'cursor-not-allowed bg-stone-200' : 'cursor-pointer'}`}
                  className={`col-span-2 md:col-span-1 p-1- md:p-2-`}
                  onClick={() => handleOptionChange(item.title)}
                >
                  {/* <img src={`/images/${item.imgName}.png`} alt={item.imgName} className={`max-w-[70%] sm:w-[auto] md:w-[80%] transition-all duration-150 ${selectedMethod === item.title ? 'scale-105' : 'scale-1'} `} /> */}
                  <div
                    className={`border rounded p-0 md:p-3 flex flex-col justify-center items-center md:h-[170px] transition-all duration-150 ease-linear ${
                      selectedMethod === item.title ? 'border-[#00a3da] bg-blue-100' : 'border-stone-200'
                    } ${isSubscription && item.title === 'Paypal' ? '!cursor-not-allowed bg-stone-200' : 'cursor-pointer'} ${
                      !isCryptoEnable && item.title === 'Crypto' ? '!cursor-not-allowed bg-stone-200' : 'cursor-pointer'
                    }`}
                  >
                    <img
                      src={`/images/payment/${item.imgName}.png`}
                      alt={item.imgName}
                      className={`max-w-[70%] sm:w-[auto] md:w-[80%] transition-all duration-150 ${
                        selectedMethod === item.title ? 'scale-105' : 'scale-1'
                      } `}
                    />
                  </div>
                </div>
              ))
            : paymentMethods.map((item, index) => {
                let showItem = true;
                if (item.title === 'Crypto' && !isCryptoEnable) {
                  showItem = false;
                }
                if (item.title === 'Adyen' && !isAdyenEnable) {
                  showItem = false;
                }
                // if (index === 2 && !isPaypalEnable) {
                //     showItem = false
                // }
                return (
                  showItem && (
                    <div
                      key={`payment${index}`}
                      data-tooltip-target
                      // className={`col-span-2 sm:col-span-2 md:col-span-1 border rounded p-0 md:p-3 flex flex-col justify-center items-center md:h-[170px] transition-all duration-150 ease-linear ${selectedMethod === item.title ? 'border-[#00a3da] bg-blue-100' : 'border-stone-200'} ${isSubscription && index === 3 ? 'cursor-not-allowed bg-stone-200' : 'cursor-pointer'}`}
                      className={`col-span-2 md:col-span-1 p-1- md:p-2-`}
                      // className={`basis-1/2 md:basis-1/3 p-1 md:p-2`}
                      onClick={() => handleOptionChange(item.title)}
                    >
                      {/* <img src={`/images/${item.imgName}.png`} alt={item.imgName} className={`max-w-[70%] sm:w-[auto] md:w-[80%] transition-all duration-150 ${selectedMethod === item.title ? 'scale-105' : 'scale-1'} `} /> */}
                      <div
                        className={`border rounded p-0 md:p-3 flex flex-col justify-center items-center md:h-[170px] transition-all duration-150 ease-linear ${
                          selectedMethod === item.title ? 'border-[#00a3da] bg-blue-100' : 'border-stone-200'
                        } ${isSubscription && item.title === 'Paypal' ? '!cursor-not-allowed bg-stone-200' : 'cursor-pointer'} ${
                          !isCryptoEnable && item.title === 'Crypto' ? '!cursor-not-allowed bg-stone-200' : 'cursor-pointer'
                        }`}
                      >
                        <img
                          src={`/images/payment/${item.imgName}.png`}
                          alt={item.imgName}
                          className={`max-w-[70%] sm:w-[auto] md:w-[80%] transition-all duration-150 ${
                            selectedMethod === item.title ? 'scale-105' : 'scale-1'
                          } `}
                        />
                      </div>
                    </div>
                  )
                );
              })}
        </div>
        <div className="grid grid-cols-6 mt-8 gap-2 md:gap-4">
          <div className="col-start-1 col-span-2 md:col-span-1">
            <button
              type="button"
              className="w-full flex items-center justify-center rounded px-6 pt-2 pb-2 font-normal md:text-lg md:h-[48px] transition duration-200 bg-[#00a3da] text-white sm:px-2 hover:bg-[#f60362] focus:outline-none focus:ring-0"
              onClick={() => props.backStep()}
            >
              Back
            </button>
          </div>
          {/* {selectedMethod === 'Crypto' && <div onClick={() => handleClick()} nacepay="Paywidget" nacepay_id="14548063-cb5e-4b42-9adb-d6c19165b8c9" amount={basketStates.grandTotal} currency={basketStates.currency} ref_id={uuid} */}
          {selectedMethod === 'Crypto' && (
            <div
              onClick={() => handleClick()}
              nacepay="Paywidget"
              nacepay_id="e0c0674c-c942-47f7-96ea-6c7a5505313a"
              amount={basketStates.grandTotal}
              currency={basketStates.currency}
              ref_id={uuid}
              className="col-span-4 sm:col-span-3 sm:col-start-4 md:col-start-5 md:col-span-2 flex justify-end [&>img]:!hidden [&>img]:max-h-10"
            >
              <div
                className={`cursor-pointer w-full flex items-center justify-center rounded px-6 pt-2 pb-2 font-normal md:text-lg md:h-[48px] transition duration-150 ease-in-out ${
                  hasMultipleCurrencyError
                    ? 'bg-stone-300 active:bg-stone-300 text-stone-600 cursor-not-allowed'
                    : 'bg-[#f60362] text-white focus:bg-[#00a3da] hover:bg-[#00a3da]'
                } focus:outline-none focus:ring-0 active:bg-[#00a3da]`}
              >
                <span>Next</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="group-hover:ml-2 h-5 ml-1 transition-all duration-150 ease-in"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                </svg>
              </div>
            </div>
          )}
          {selectedMethod === 'Paypal' && (
            <div className="col-start-3 col-span-4 sm:col-span-3 md:col-start-5 md:col-span-2 h-[40px] md:h-[48px] overflow-hidden">
              {basketStates.mode === 'subscription' ? (
                <PayPalButtons
                  createSubscription={(data, actions) => {
                    // console.log("===========A======== subscription", paypalId)
                    return actions.subscription.create({
                      plan_id: paypalId,
                    });
                  }}
                  onApprove={(data, actions) => {
                    console.log('onapprove subscription', data);
                    handleApprovePaypalSubscription(data);
                  }}
                />
              ) : (
                <PayPalButtons
                  createOrder={(data, actions) => {
                    console.log('currency in createOrder', basketStates.currency);
                    handlePaymentMethodDataLayer('Paypal', selectedMethod);
                    return actions.order.create({
                      purchase_units: [
                        {
                          description: basketStates.products[0].product.name,
                          amount: {
                            currency_code: basketStates.currency,
                            value: basketStates.grandTotal,
                          },
                        },
                      ],
                    });
                  }}
                  onApprove={(data, actions) => {
                    return actions.order.capture().then(details => {
                      console.log('onapprove', details);
                      handleApprovePaypalOrder(details);
                    });
                  }}
                />
              )}
            </div>
          )}
          {selectedMethod !== 'Paypal' && selectedMethod !== 'Crypto' && (
            <div className="col-span-4 sm:col-span-3 sm:col-start-4 md:col-start-5 md:col-span-2">
              <button
                type="submit"
                disabled={isLoading || hasMultipleCurrencyError}
                // onClick={() => selectedMethod === 'Adyen' ? getAdyenSession() : getStripeSession()}
                onClick={() => handleStripeAdyen()}
                className={`w-full flex items-center justify-center rounded px-6 pt-2 pb-2 font-normal md:text-lg md:h-[48px] transition duration-150 ease-in-out ${
                  hasMultipleCurrencyError
                    ? 'bg-stone-300 active:bg-stone-300 text-stone-600 cursor-not-allowed'
                    : 'bg-[#f60362] text-white focus:bg-[#00a3da] hover:bg-[#00a3da]'
                } focus:outline-none focus:ring-0 active:bg-[#00a3da] `}
              >
                {isLoading ? (
                  <>
                    Processing...
                    <svg className="animate-spin ml-4 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </>
                ) : (
                  <>
                    <span>Next</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="group-hover:ml-2 h-5 ml-1 transition-all duration-150 ease-in"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                    </svg>
                  </>
                )}
              </button>
            </div>
          )}
          {showModal && (
              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
                   onClick={() => setShowModal(false)}>
                <div className="max-h-[90vh] overflow-y-auto">
                  <div
                      id="dropin-container"
                      className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full relative flex flex-col"
                      onClick={e => e.stopPropagation()}
                  >
                    {/* Adyen Drop-in will mount here */}

                    {/* Spinner Overlay */}
                    <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
                      <div className="spinner"></div>
                    </div>

                    {/* Spacer to ensure the trust row is at the bottom */}
                    <div className="mt-auto">
                      {/* Trust Rows */}
                      <div className="border-t pt-4">
                        {/* Row 1: Text Labels */}
                        <div className="flex justify-between mb-2">
                          <div className="flex-1 text-center">
                            <span className="text-gray-700 font-medium">Secured by Adyen</span>
                          </div>
                          <div className="flex-1 text-center">
                            <span className="text-gray-700 font-medium">PCI DSS Compliant</span>
                          </div>
                        </div>

                        {/* Row 2: Logos */}
                        <div className="flex justify-between items-center">
                          <div className="flex-1 flex justify-center">
                            {/* Adyen SVG Logo */}
                            <svg
                                fill="#0abf53"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 90 30"
                                aria-label="Adyen logo"
                                role="img"
                                className="h-6 w-auto max-w-xs"
                            >
                              <path
                                  d="M27.7851 18.5234H26.0557C25.555 18.5234 25.1454 18.1138 25.1454 17.6131V6.622H21.7093C19.957 6.622 18.5234 8.05563 18.5234 9.80784V19.3198C18.5234 21.0721 19.957 22.5057 21.7093 22.5057H34.4071V0H27.7851V18.5234ZM12.6978 6.6221H0.22756V10.5816H8.35145C8.85209 10.5816 9.26169 10.9913 9.26169 11.4919V18.5235H7.53224C7.0316 18.5235 6.622 18.1139 6.622 17.6132V12.5614H3.18584C1.43363 12.5614 0 13.995 0 15.7473V19.2972C0 21.0494 1.43363 22.483 3.18584 22.483H15.8837V9.78518C15.8837 8.05573 14.4501 6.6221 12.6978 6.6221ZM44.6018 18.5235H46.3313V6.6221H52.9533V25.9419C52.9533 27.6942 51.5196 29.1278 49.7674 29.1278H37.2971V24.4856H46.3313V22.5058H40.2554C38.5032 22.5058 37.0696 21.0722 37.0696 19.3199V6.6221H43.6916V17.6132C43.6916 18.1139 44.1012 18.5235 44.6018 18.5235ZM68.2908 6.6221H55.593V19.32C55.593 21.0722 57.0266 22.5058 58.7788 22.5058H71.2491V18.5462H63.1252C62.6246 18.5462 62.215 18.1366 62.215 17.636V10.5816H63.9444C64.4451 10.5816 64.8547 10.9913 64.8547 11.4919V16.5437H68.2908C70.043 16.5437 71.4767 15.1101 71.4767 13.3579V9.80794C71.4767 8.05573 70.043 6.6221 68.2908 6.6221ZM74.1163 6.6221H86.8141C88.5891 6.6221 90 8.05573 90 9.78518V22.483H83.378V11.4919C83.378 10.9913 82.9684 10.5816 82.4677 10.5816H80.7383V22.5058H74.1163V6.6221Z"></path>
                            </svg>
                          </div>
                          <div className="flex-1 flex justify-center">
                            {/* PCI DSS PNG Logo */}
                            {/* If using the public folder */}
                            <img src={`/images/payment/pci.png`} alt="PCI DSS Logo" className="h-6 w-auto max-w-xs"/>

                            {/* If using an imported image from assets */}
                            {/* <img
                      src={pciDssLogo}
                      alt="PCI DSS Logo"
                      className="h-6 w-auto max-w-xs"
                    /> */}
                          </div>
                        </div>

                        {/* Row 3: Additional Text */}
                        <div className="mt-2 text-center text-sm text-gray-600">
                          Donations are processed securely through Adyen, a PCI DSS certified payment gateway.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default StepThreeWithLocalStorage;
